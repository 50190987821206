import './media.scss';

document.addEventListener("DOMContentLoaded", function () {
    const elements = document.getElementsByClassName('youtube__overlay');
    for (let element of elements) {
        element.addEventListener("click", function () {
            const yt = element.previousSibling.previousSibling;
            let iframe = document.createElement('iframe');
            iframe.setAttribute('src', yt.getAttribute('data-iframe-src'));
            iframe.setAttribute('width', yt.getAttribute('data-iframe-width'));
            iframe.setAttribute('height', yt.getAttribute('data-iframe-height'));
            iframe.setAttribute('allow', yt.getAttribute('data-iframe-allow'));
            iframe.setAttribute('frameborder', yt.getAttribute('data-iframe-frameborder'));
            if (yt.getAttribute('data-iframe-allowfullscreen') === "true") {
                iframe.setAttribute('allowfullscreen', "true");
            }

            const parent = yt.parentNode;
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }
            parent.appendChild(iframe);
        });
    }
});