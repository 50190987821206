import 'justifiedGallery/dist/css/justifiedGallery.css'

let jquery = require('jquery');
window.$ = window.jQuery = jquery;

require('justifiedGallery');
require("@fancyapps/fancybox");

document.addEventListener("DOMContentLoaded", function () {
    (function ($) {
        $.each($('.gallery-items'), function (index, el) {
            $(el).justifiedGallery({
                rowHeight: 250,
            });
        });
    }(jquery));
    (function () {
        $("[data-fancybox]").fancybox({
            buttons: ["fullScreen", "close"],
            hideScrollbar: false,
            clickContent: "close",
            caption: function (instance, item) {
                let name = $(this).data('name') || '';
                let year = $(this).data('year') || '';
                let medium = $(this).data('medium') || '';
                let dimensions = $(this).data('dimensions') || '';

                let name_line = name.length ? '<strong>' + name + '</strong><br />' : '';
                let info_line = (('' + year).length ? year + ', ' : '') + (medium.length ? medium + ', ' : '') + dimensions;

                return (name_line.length ? name_line : '') + info_line;
            }
        })
    }(jquery));
});